<template>
    <div class="h-100 w-100 flex-in-middle dark" v-show="page_ready" :style="{backgroundImage:'url('+bg_image+')'}">
        <div class="login-wrap">
            <div class="left-part">
                <div class="title">{{ $t("TITLE.WELCOME") }}</div>
                <div class="line"></div>
                <div class="text">{{ $t("MESSAGE.WELCOME") }}
                </div>
            </div>
            <div class="right-part">
                <el-tabs v-model="tab" stretch v-el-tabs-large-header>
                    <el-tab-pane :label="$t('TITLE.LOCAL')" name="local" class="position-relative tab-pane active">
                        <div class="">
                            <div style="margin-top: 34px;">
                                <el-input v-model="login.id" clearable placeholder="Your ID"
                                          v-el-round-input></el-input>
                            </div>
                            <div style="margin-top: 34px;">
                                <el-input v-model="login.password" show-password type="password" clearable
                                          placeholder="Password" v-el-round-input></el-input>
                            </div>
                            <div style="margin-top: 43px;">
                                <el-button type="dark" round class="w-100" @click="submitForm" style="height: 45px;">
                                    {{ $t("TITLE.SIGN-IN") }}
                                </el-button>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="language">
                <div class="mr-2 locale" @click="setLocale">{{ english ? "English" : "中文" }}</div>
            </div>
            <div style="position:fixed;bottom: 0px;left: 0;right: 0;text-align: center">
                <page-footer></page-footer>
            </div>
        </div>
    </div>
</template>

<script>

import SignIn from "@/views/mixins/signin-mixin"
import "@/common/directives/el-tabs-large-header"
import "@/common/directives/el-round-input"
import PageFooter from "@/components/page-footer";

export default {
    components: {PageFooter},
    mixins: [SignIn],
    data() {
        return {
            tab: 'local',
        }
    },
    created() {
        // 判断是否在cookie中有了token
    },
}
</script>

<style lang="scss">
@import "../scss/signin-dark.scss";

</style>
